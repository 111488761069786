
import axios from 'axios';

const baseUrl = 'https://dev-backend.abamade.com.ng/api/vendor';
const headers = {
    "Authorization": "Bearer " + localStorage.getItem("abamade_auth_vendor_token")
};

class WalletService {
    static setPin(pin){
        return new Promise(async (resolve, reject)=>{
            try {
                const sendOtp = await axios.put(`${ baseUrl}/wallet/wallet-pin`, {pin: pin}, {headers})
                const data = sendOtp.data
                resolve(data)
            } catch (error) {
                reject(error)
            }
        })
    }

    static getWallet(){
        return new Promise(async (resolve, reject)=>{
            try {
                const wallet = await axios.get(`${baseUrl}/wallet/get-wallet`, { headers})
                const data = wallet.data
                resolve(data)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default WalletService