import axios from 'axios'
const baseUrl = "https://dev-backend.abamade.com.ng/api/vendor"
import store from '../store'


class AccountServices{
    static getStoreInfo(token){
        return new Promise(async (resolve, reject)=>{
            try {
                const info = await axios.get(`${baseUrl}/store`, { headers: {
                    Authorization: `Bearer ${ token }`
                } })
                resolve(info.data.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    static updateStore(storeToken , updatedData){
        return new Promise(async (resolve, reject)=>{
            try{
                const update = await axios.put(`${baseUrl}/store/manufacturer/details`, updatedData, { headers: {
                    Authorization: `Bearer ${storeToken}`,
                    'Content-Type': 'multipart/form-data'
                } })
                const data = update.data
                resolve(data)
            }catch(error){
                reject(error)
            }
        })
    }
}

export default AccountServices