import { createStore } from 'vuex'
import router from '../router';
import axios from 'axios';
import chat from './modules/chat';
import withdraw from './modules/withdraw'
import registration from './modules/registration';
import wallet from './modules/wallet'
import AccountServices from '../services/AccountServices'
export default createStore({
  state: {
    businessType: '',
    forgotStages: {
      step: 1,
      loading: false,
      disabled: false,
      errorMsg: "",
      error: "",
      forgotEmail: "",
      token: "",
      successMsg: "",
      success: false
    },
    kycData: {
      allBanks: [],
      modal: "",
      selectedDocument: "",
      documentImages: [],
      accountNumber: "",
      accountName: "",
      bank: "",
      kycStatus: "",
      loading: false,
      kycSuccess: false,
      kycMessage: ""
    },
    loading: false,
    disabled: false,
    token: null,
    userId: null,
    general: true,
    variations: false,
    pricing: false,
    preview: false,
    accountsettings:{
      tab: "profile"
    },
    user: {},
    editProductDetails: {},
    quotationMessage: "",
    quotationStatus: false
  },
  getters: {
    getKycSuccess(state){
      return state.kycData.kycSuccess
    },
    getKycMessage(state){
      return state.kycData.kycMessage
    },
    getKycModal(state){
      return state.kycData.modal
    },
    getKycLoading(state){
      return state.kycData.loading
    },
    getForgotSuccess(state){
      return state.forgotStages.success
    },
    getForgotSuccessMsg(state){
      return state.forgotStages.successMsg
    },
    getForgotEmail(state){
      return state.forgotStages.forgotEmail
    },
    getForgotLoading(state){
      return state.forgotStages.loading
    },
    getForgotErrorMsg(state){
      return state.forgotStages.errorMsg
    },
    getForgotError(state){
      return state.forgotStages.error
    },
    getForgotStep(state){
      return state.forgotStages.step
    },
    isLoading(state){
      return state.loading
    },
    isDisabled(state){
      return state.disabled
    },
    getkycStatus(state){
      return state.kycData.kycStatus
    },
    user(state){
      return state.user;
    },
    isAuthenticated(state) {
      return state.token !== null;
    },
    getAllBanks(state){
      return state.kycData.allBanks
    },
    getQuotationStatus(state){
      return state.quotationStatus
    },
    getQuotationMessage(state){
      return state.quotationMessage
    },
    getAccountType(state){
      return state.businessType
    }
  },
  mutations: {
    setAccountType(state, data){
      state.businessType = data
    },
    kycSuccess(state, message){
      state.kycData.success = true
    },
    triggerKycModal(state, type){
      state.kycData.modal = type
    },
    setKycStatus(state, status){
      state.kycData.kycStatus = status
    },
    loginEmptyError(state){
      
      state.forgotStages.error = true
      state.forgotStages.errorMsg = "No field can be left blank"
      setTimeout(()=>{
        state.forgotStages.error = false
        state.forgotStages.errorMsg = ""
      }, 3000)
    },
    shortPwdError(state){
      state.forgotStages.error = true
      state.forgotStages.errorMsg = "Password must be up to 8 characters"
      setTimeout(()=>{
        state.forgotStages.error = false
        state.forgotStages.errorMsg = ""
      }, 3000)
    },
    setMismatchPass(state){
      state.forgotStages.error = true
      state.forgotStages.errorMsg = "Passwords do not match"

      setTimeout(()=>{
        state.forgotStages.error = false
        state.forgotStages.errorMsg = ""
      }, 2000)
    },
    changeTab(state, tab){
      state.accountsettings.tab = tab
    },
    setKycSuccess(state, status){
      state.kycData.kycSuccess = status
    },
    setKycMessage(state, message){
      state.kycData.kycMessage = message
    },

    setQuotationMessage(state, message){
      state.quotationMessage = message
    },

    setQuotationStatus(state, status){
      state.quotationStatus = status
    },
    
    saveToken(state, token){
      state.storeSetup.token = token
    },
    setUserToken(state, {token, userId}){
      localStorage.setItem("abamade_auth_vendor_token", token)
        state.token = token
        state.userId = userId

    },
    setKycLoading(state, status){
      state.kycData.loading = status
    },
    logout(state){
      state.token = null
    },
    getUserData(state, user){
      state.user = user
      localStorage.setItem("user", user)
      this.commit('setUserRegistrationData', user)
    },
    setBanks(state, banks){
      state.kycData.allBanks = banks
    }
  },
  actions: {

    async login({ commit, state}, authData){

      state.loading = true
      state.disabled = true
      try {
        const loginUser = await axios.post("https://dev-backend.abamade.com.ng/api/user/vendor-login", authData)
        state.token = loginUser.data.token
        state.loading = false
        state.disabled = false
        localStorage.setItem("abamade_auth_vendor_token", loginUser.data.token)
        state.loginMessage = "Login successfull"
        
        
          AccountServices.getStoreInfo(loginUser.data.token).then(() => {
            commit("setStoreStatus", true);
            window.location.href = "/"
          }).catch((error) => {
            console.log(error);
            commit("setStoreStatus", false);
            window.location.href = "/signup"
          })
          

        setTimeout(()=>{
          state.loginMessage = ''
        }, 3000)
      } catch (error) {
        console.log('the error ==>', error)
        state.forgotStages.error = true
        state.forgotStages.errorMsg = error.response.data.errors[0].error
        setTimeout(()=>{
          state.forgotStages.error = false
          state.forgotStages.errorMsg = ""
        },3000)
        state.loading = false
        state.disabled = false
      }
      
    },
    async fetchUser({ commit, state }) {
      try {
        const { data } = await axios.get("https://dev-backend.abamade.com.ng/api/user/info", {
          headers: { 'Authorization': `Bearer ${localStorage.getItem("abamade_auth_vendor_token")}`}
        })

        localStorage.setItem("userId", data.data._id)
        commit('getUserData', data.data)
      } catch(error) {
        console.log(error);
        commit("setStage", 1)
        console.log(error)
        if(error.response) {
          if(error.response.data.message === "jwt expired") {
            localStorage.removeItem("abamade_auth_vendor_token")
            commit('logoutUser')
            router.push('/login')
          }
        }
      }
    },
    logoutUser({commit}) {
      localStorage.removeItem("abamade_auth_vendor_token")
      localStorage.removeItem("user")
      localStorage.removeItem("userId")
      commit('logout');
      router.replace('/login');
    },
    sendForgotMail({commit, state}, email){
      state.forgotStages.loading = true

      axios.post("https://dev-backend.abamade.com.ng/api/auth/password/forgot-password", {email})
      .then((response)=>{
        state.forgotStages.loading = false
        state.forgotStages.step = 2
        state.forgotStages.forgotEmail = email
      })
      .catch((error)=>{
        state.forgotStages.loading = false
        state.forgotStages.error = true
        state.forgotStages.errorMsg = error.response.data.errors[0].error

        setTimeout(()=>{
          state.forgotStages.error = false
          state.forgotStages.errorMsg = ""
        }, 3000)

        console.log(error);
      })
    },
    verifyForgotOtp({commit, state}, otp){
      state.forgotStages.loading = true
      axios.post("https://dev-backend.abamade.com.ng/api/auth/password/verify", {otp, email: state.forgotStages.forgotEmail})
      .then((response)=>{
        state.forgotStages.loading = false
        state.forgotStages.token = response.data.data
        state.forgotStages.step = 3
      })
      .catch((error)=>{
        state.forgotStages.loading = false
        state.forgotStages.error = true
        state.forgotStages.errorMsg = error.response.data.errors[0].error
        console.log(error);
      })
    },
    resetPasswordFinal({commit, state}, password){
      state.forgotStages.loading = true
      axios.put("https://dev-backend.abamade.com.ng/api/auth/password/reset", {token: state.forgotStages.token, password})
      .then((response)=>{
        state.forgotStages.successMsg = response.data.data
        state.forgotStages.loading = false
          state.forgotStages.success = true
        router.push("/login")
        setTimeout(()=>{
          state.forgotStages.successMsg = ""
          state.forgotStages.success = false
          
        },3000)
      })
      .catch((error)=>{
        state.forgotStages.loading = false
        state.forgotStages.error = true
        state.forgotStages.errorMsg = error.response.data.errors[0].error

        setTimeout(()=>{
          state.forgotStages.error = false
          state.forgotStages.errorMsg = ""
        },3000)
        console.log(error);
      })
    },
    saveKycInfo({commit, state}, data){
      console.log('images', data.documentImages);
      state.kycData.selectedDocument = data.selectedDocument
      state.kycData.documentImages = data.documentImages

      commit("triggerKycModal", 'acc')
    },
    sendKyc({commit, state}, data){
      console.log(state.kycData.documentImages);
      const info = {
        selectedDocument: state.kycData.selectedDocument,
        selectedBank: data.selectedBank,
        recipientAccountNumber: data.accNumber,
        accountName: data.accName,
        documentImages: state.kycData.documentImages,
        bankCode: data.bankCode
      }

      axios.post("https://dev-backend.abamade.com.ng/api/vendor/kyc/kyc-submission", info, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('abamade_auth_vendor_token')}`}
      })

      .then((response)=>{
        commit("changeTab", "upgrade")
        commit("setKycLoading", false)
        commit('triggerKycModal', "")
        commit("setKycSuccess", true)

      })
      .catch((error)=>{
        commit("setKycLoading", false)
        console.log(error);
      })
    },

    getKycStatus({commit, state}){
      try {
        axios.get("https://dev-backend.abamade.com.ng/api/vendor/kyc/kyc-status", {
          headers: { 'Authorization': `Bearer ${localStorage.getItem("abamade_auth_vendor_token")}`}
        })
        .then((response)=>{
          console.log(response.data.data);
          commit("setKycStatus", response.data.data)
        })
        .catch((error)=>{
          console.log(error);
        })
      } catch (error) {
        console.log(error);
      }
    },

    getAllBanks({commit}){
      const banks = axios.get("https://dev-backend.abamade.com.ng/api/vendor/kyc/kyc-banks")
      .then((response)=>{
          commit("setBanks", response.data.data.data)
      })
      .catch((error)=>{
          console.log(error);
      })
  },
    
  },
  modules: {
    chat, registration, wallet, withdraw
  }
})
